
import { Vue, Component } from 'vue-property-decorator'
import { apiPartnerLists, apiPartnerCharge } from '@/api/partner'
import { throttle } from '@/utils/util'

@Component({
    components: {
        
    }
})
export default class ListsDetail extends Vue {
    /** S Data **/

    // 合作商列表
    partnerData: any = []

    // 添加门店表单数据
    form: any = {
        action: 1,                    // 名称
        partner_id: '',               // 合作商ID
        num: '',                      // 数量
        remark: ''                    // 备注
    };

    // 表单校验
    rules = {
        partner_id: [{ required: true, message: '请选择合作商', trigger: ['blur', 'change'] }],
        action: [{ required: true, message: '请调整君币类型', trigger: 'change' }],
        num: [{ required: true, message: '请输入调整数量', trigger: ['blur', 'change'] }],
        remark: [{ required: true, message: '请输入备注', trigger: ['blur', 'change'] }],
    };

    /** E Data **/

    get partnerGold() {
        const id = this.form.partner_id
        const item = this.partnerData.filter((item: any) => item.id == id)
        console.log(item)
        if ( item.length == 0 ) {
            return ''
        } else {
            return item[0].gold
        }
    }

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return

            this.handleShopAdd()
        })
    }

    // 充值
    async handleShopAdd(): Promise<void> {
        await apiPartnerCharge({ ...this.form })
        setTimeout(() => this.$router.go(-1), 500)
    }

    // 获取合作商列表
    async getPartnerLists(): Promise<void> {
        const { lists } = await apiPartnerLists({
            page_size: 100
        })
        this.partnerData = lists
    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        this.onSubmit = throttle(this.onSubmit, 2000)
        this.getPartnerLists()
    }
    /** E Life Cycle **/
}
